.container {
  background-color: #e3f2fd; /* Light Blue */
  padding: 2rem;
  text-align: center;
}
#DoctorsDirectory {
  height: 100vh;
  overflow-y: auto;
}
.timings-section {
  height: 100vh;
  overflow-y: auto;
}
.faq-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
}
.main-navigation {
  width: 100%;
  max-width: 5xl; /* Ensure this value is valid or use a standard unit like px */
  background-color: white;
  padding: 4px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around; /* Corrected from 'around' to 'space-around' */
  /* ... other styles ... */
}

/* Adjustments for tablet and large phones */
@media (max-width: 768px) {
  .main-navigation {
    flex-direction: column;
    align-items: center; /* Center items when stacked */
    padding: 10px 0; /* Increase padding */
  }

  .main-navigation a {
    display: block; /* Make links take full width */
    margin-bottom: 10px; /* Add space between links */
  }
}

/* Adjustments for smaller phones */
/* Adjustments for smaller phones */
@media (max-width: 480px) {
  .main-navigation {
    padding: 15px 0; /* Increase padding for better touch targets */
  }

  .main-navigation a {
    font-size: 16px; /* Increase font size for better readability */
    padding: 10px 15px; /* Increase padding for easier touch */
    margin: 5px 0; /* Add some space between links */
  }

  /* If you have a logo or other elements in the navbar, adjust them here */
  .logo {
    width: 150px; /* Example: Adjust the size of the logo */
    height: auto; /* Maintain aspect ratio */
  }

  /* Any other specific adjustments for smaller phone screens can go here */
}
